import Layout from "components/Layout";
import CustomerReviews from "components/homepageSections/CustomerReviews";
import ExperienceEnte from "components/homepageSections/ExperienceEnte";
import FAQs from "components/homepageSections/FAQs";
import Features from "components/homepageSections/Features";
import HeroSection from "components/homepageSections/HeroSection";
import MemoriesPreserved from "components/homepageSections/MemoriesPreserved";
import Pricing from "components/homepageSections/Pricing";
import WhyEnte from "components/homepageSections/WhyEnte";
import { PageProps, graphql } from "gatsby";
import "styles/landing.css";
import { FluidImageData } from "types";
import React from "react";
import styled from "styled-components";
import MentionsTicker from "components/homepageSections/MentionsTicker";

interface IPageData {
    featureImage: FluidImageData;
    feature1: FluidImageData;
    feature2: FluidImageData;
    feature3: FluidImageData;
    feature4: FluidImageData;
}

interface HeroSectionProps {
    headingText: string;
    headingHighlightText: string;
    paragraphText: string;
    buttonText: string;
    backdropBackground?: string;
    backdropBackgroundImage?: string;
    duckyShieldColor: string;
}

interface ExperienceEnteProps {
    background: string;
    heading: string;
    subheading: string;
    icon: string;
}

const EmptyContainer = styled.section.attrs({
    className: "container",
})`
    display: flex;
    align-items: stretch;
    flex-direction: column;
`;

const EmptyBackdrop = styled.div.attrs({
    className: "g-0 pt-0 pb-0 pt-lg-5 pb-lg-5",
})`
    overflow: hidden;
    color: #0c4401;
    border-radius: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    background: radial-gradient(
            70.11% 70.11% at 50% 50%,
            #86ff6d 0%,
            #67d451 32.99%,
            #58bf43 60.59%
        ),
        #58bf43;
    min-height: 450px;

    @media (max-width: 512px) {
        flex-direction: column;
        height: calc(100svh - 94px);
        justify-content: space-between;
        border-radius: 15px;

        @supports (-webkit-touch-callout: none) {
            height: calc(-webkit-fill-available - 94px);
        }
    }

    @media (min-width: 512px) and (max-width: 992px) {
        flex-direction: column;
        height: calc(100svh - 216px);
        border-radius: 15px;

        @supports (-webkit-touch-callout: none) {
            height: calc(-webkit-fill-available - 216px);
        }
    }

    @media (min-width: 1440px) {
        height: calc(100svh - 160px);
    }

    @media (min-width: 1920px) {
        height: 700px;
    }
`;

const Index: React.FC<PageProps<IPageData>> = ({
    path,
    data: { featureImage, feature1, feature2, feature3, feature4 },
}) => {
    const heroSectionProps: HeroSectionProps = {
        headingText: "for your photos",
        headingHighlightText: "Safe Home",
        paragraphText:
            "Store, share, and discover your memories with end-to-end encryption",
        buttonText: "Download",
        backdropBackgroundImage: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaWlDQ1BEaXNwbGF5IFAzAAB4nHWQvUvDUBTFT6tS0DqIDh0cMolD1NIKdnFoKxRFMFQFq1OafgltfCQpUnETVyn4H1jBWXCwiFRwcXAQRAcR3Zw6KbhoeN6XVNoi3sfl/Ticc7lcwBtQGSv2AijplpFMxKS11Lrke4OHnlOqZrKooiwK/v276/PR9d5PiFlNu3YQ2U9cl84ul3aeAlN//V3Vn8maGv3f1EGNGRbgkYmVbYsJ3iUeMWgp4qrgvMvHgtMunzuelWSc+JZY0gpqhrhJLKc79HwHl4plrbWD2N6f1VeXxRzqUcxhEyYYilBRgQQF4X/8044/ji1yV2BQLo8CLMpESRETssTz0KFhEjJxCEHqkLhz634PrfvJbW3vFZhtcM4v2tpCAzidoZPV29p4BBgaAG7qTDVUR+qh9uZywPsJMJgChu8os2HmwiF3e38M6Hvh/GMM8B0CdpXzryPO7RqFn4Er/QcXKWq8MSlPPgAAABBJREFUeAEBBQD6/wAIwiX/A7QB7/maltEAAAAASUVORK5CYII=`,
        duckyShieldColor: "#3EB54A",
    };

    const experienceEnteProps: ExperienceEnteProps = {
        background:
            "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaWlDQ1BEaXNwbGF5IFAzAAB4nHWQvUvDUBTFT6tS0DqIDh0cMolD1NIKdnFoKxRFMFQFq1OafgltfCQpUnETVyn4H1jBWXCwiFRwcXAQRAcR3Zw6KbhoeN6XVNoi3sfl/Ticc7lcwBtQGSv2AijplpFMxKS11Lrke4OHnlOqZrKooiwK/v276/PR9d5PiFlNu3YQ2U9cl84ul3aeAlN//V3Vn8maGv3f1EGNGRbgkYmVbYsJ3iUeMWgp4qrgvMvHgtMunzuelWSc+JZY0gpqhrhJLKc79HwHl4plrbWD2N6f1VeXxRzqUcxhEyYYilBRgQQF4X/8044/ji1yV2BQLo8CLMpESRETssTz0KFhEjJxCEHqkLhz634PrfvJbW3vFZhtcM4v2tpCAzidoZPV29p4BBgaAG7qTDVUR+qh9uZywPsJMJgChu8os2HmwiF3e38M6Hvh/GMM8B0CdpXzryPO7RqFn4Er/QcXKWq8MSlPPgAAABBJREFUeAEBBQD6/wAIwiX/A7QB7/maltEAAAAASUVORK5CYII=) repeat",
        heading: "Experience Ente Photos",
        subheading: "Scan the QR code to download the app",
        icon: "ente",
    };

    return (
        <Layout {...{ path, shouldShowDownloads: true }}>
            <HeroSection images={[featureImage]} {...heroSectionProps} />
            <MentionsTicker />
            <Features images={[feature1, feature2, feature3, feature4]} />
            <WhyEnte />
            <ExperienceEnte {...experienceEnteProps} />
            <CustomerReviews />
            <Pricing />
            <MemoriesPreserved />
            <FAQs />
        </Layout>
    );
};

export default Index;

export const pageQuery = graphql`
    query HeaderImageQuery {
        featureImage: imageSharp(
            fluid: { originalName: { eq: "feature.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature1: imageSharp(fluid: { originalName: { eq: "feature-1.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature2: imageSharp(fluid: { originalName: { eq: "feature-2.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature3: imageSharp(fluid: { originalName: { eq: "feature-3.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        feature4: imageSharp(fluid: { originalName: { eq: "feature-4.png" } }) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
    }
`;
