import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import AndroidHeadlines from "images/homepage/AndroidHeadlines";
import AndroidPolice from "images/homepage/AndroidPolice";
import HackerNews from "images/homepage/HackerNews";
import PrivacyTools from "images/homepage/PrivacyTools";
import Reddit from "images/homepage/Reddit";
import PrivacyGuides from "images/homepage/PrivacyGuides";
import LinuxTechTips from "images/homepage/LinusTechTips";
import Caschy from "images/homepage/Caschy";
import Wired from "images/homepage/Wired";
import TechCrunch from "images/homepage/TechCrunch";

const Container = styled.section`
    position: relative;

    overflow: hidden;
    white-space: nowrap;

    margin-top: 72px;
    margin-bottom: 0px;

    @media (min-width: 992px) {
        margin-top: 165px;
        margin-bottom: 0px;
    }
`;

const Content = styled.div`
    animation: wave 45s linear infinite;
`;

const FadeLeft = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 10%; /* Adjust width as needed */
    height: 100%;
    background: linear-gradient(to right, white, transparent);
    pointer-events: none;
    z-index: 3;
`;

const FadeRight = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 10%; /* Adjust width as needed */
    height: 100%;
    background: linear-gradient(to left, white, transparent);
    pointer-events: none;
    z-index: 3;
`;

export default function MentionsTicker() {
    const [repeat, setRepeat] = useState(2);
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (ref.current) {
            const { scrollWidth } = ref.current;
            const { innerWidth } = window;

            if (scrollWidth < 2 * innerWidth) {
                setRepeat((prev) => prev + 1);
            }
        }
    }, [ref.current?.scrollWidth]); // Ensures re-run when width changes

    return (
        <Container>
            <FadeLeft />
            <Content ref={ref}>
                {Array.from({ length: repeat }).map((_, index) => (
                    <React.Fragment key={index}>
                        <PrivacyTools />
                        <HackerNews />
                        <LinuxTechTips />
                        <Wired />
                        <TechCrunch />
                        <AndroidHeadlines />
                        <PrivacyGuides />
                        <Reddit />
                        <AndroidPolice />
                    </React.Fragment>
                ))}
            </Content>
            <FadeRight />
        </Container>
    );
}
