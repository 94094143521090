import React from "react";
import { ISVGProps } from "types";

export default function Wired(props: ISVGProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0.11 10.99 124.78 24.98"
            className="mentions-ticker-element"
        >
            <path
                d="M105.375 14.875v17.25h8.5c2.375 0 3.75-.375 4.75-1.25 1.25-1.125 1.875-3.125 1.875-7.375s-.625-6.25-1.875-7.375c-1-.875-2.375-1.25-4.75-1.25zM117 23.5c0 3.75-.25 4.625-1 5.125-.5.375-1.125.5-2.375.5h-4.75V17.75h4.75c1.25 0 1.875 0 2.375.5.75.625 1 1.5 1 5.25zm7.875 12.438H99.937V11h24.938zM79.563 17.75v-2.875h14.75v5.5h-3.126V17.75h-6v4.125h4.75v2.75h-4.75v4.625h6.126v-3h3.124v5.875H79.564V29.25h2.374v-11.5zm-13.375 9.875c0 1.875.124 3.25.374 4.375h3.376c-.126-.875-.25-2.5-.25-4.625-.126-2.5-.876-2.875-2.626-3.25 2-.375 2.876-1.25 2.876-4.375 0-2.5-.376-3.5-1.126-4.125-.5-.5-1.374-.75-2.75-.75h-10.5v17.25h3.5v-6.75h4.876c1 0 1.374.125 1.75.375s.5.625.5 1.875zm-7.126-5v-4.75h5.626c.75 0 1 .125 1.124.25.25.25.5.625.5 2.125s-.25 2-.5 2.25c-.124.125-.374.25-1.124.25zm15.876 13.313h-25V11h24.937v24.938zm-31.5-6.688v2.875H31.562V29.25h4.25v-11.5h-4.25v-2.875h11.875v2.875h-4.25v11.5zM23.375 14.875h-3.25L17.75 28.5 15 15.875c-.125-.875-.5-1-1.25-1H12c-.75 0-1.125.25-1.25 1L8 28.5 5.625 14.875h-3.5L5.5 31.25c.125.75.375.875 1.25.875h2.375c.75 0 1-.125 1.25-.875L13 19.375l2.625 11.875c.125.75.375.875 1.25.875h2.25c.75 0 1.125-.125 1.25-.875zm1.75 21.063h-25V11h24.938v24.938z"
                style={{
                    fill: "#939393",
                }}
            />
        </svg>
    );
}
